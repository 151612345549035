import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Select } from "antd";
import { toggleCollapsedNav, onMobileNavToggle, onLocaleChange } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import * as perRes from '../../services/PermissionsResolver';
import utils from 'utils'
import NavProfile from "./NavProfile";
import { useHistory, useLocation } from "react-router-dom";
import { axiosInstance } from "services/AdminSiteService";
import { useIntl } from "react-intl";


const { Header } = Layout;
const { Option } = Select;

export const HeaderNav = (props) => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction, locale, onLocaleChange, currentUser } = props;
  const [searchActive, setSearchActive] = useState(false)

  const [activeMobValNav, setActiveMobValNav] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  const intl = useIntl();

  const langSelectedChange = (value) => {
    onLocaleChange(value);
    axiosInstance.defaults.headers.locale = value;
    localStorage.setItem("locale", locale);
  }

  const history = useHistory();
  const routeChange = (aPath) => {
    let path = `/app/${aPath}`;
    history.push(path);
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  const toggleMobileNav = () => {
    setActiveMobValNav(!activeMobValNav)
  }

  const location = useLocation();


  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
    setCurrentPath(location.pathname)
  })

  return (
    <div>
      <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
        <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
          {/* <Logo logoType={navMode}/> */}
          <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
            <div className="nav-left">
              {/* <ul className="ant-menu ant-menu-root ant-menu-horizontal">          
              {
                isNavTop && !isMobile ?
                null
                :
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </li>
              }
            </ul> */}
              <div className="sidenav-logo-cnt alt-logo-nav">
                <img className="img-fluid" src="/img/vid_logo.svg" alt="" />

              </div>

            </div>
            <div className="nav-right">
              {/* <NavPanel direction={direction} />
            <NavSearch active={searchActive} close={onSearchClose}/> */}
              {/* <input className="header-nav-search"></input> */}

              <Select onChange={langSelectedChange} className="lang-select-input" defaultValue={locale}>
                <Option key={'en'} value="en">EN</Option>
                <Option key={'es'} value="es">ES</Option>
              </Select>
              {/* <div className="lang-selector">
                <span onClick={() => onLocaleChange('es')} className={locale == 'es' ? 'active-lang' : ''}>ES</span>
                <div className="separator-vl"></div>
                <span onClick={() => onLocaleChange('en')} className={locale == 'en' ? 'active-lang' : ''}>EN</span>
              </div> */}
              <NavProfile />
              <img onClick={toggleMobileNav} className="img-fluid menu-burger" src="/img/menu_burguer.svg" alt="" />
            </div>
          </div>
        </div>

      </Header>
      {
        activeMobValNav ? <div className="mobile-val-nav">
          <ul>
            <li className={`${currentPath === '/app/home' ? "active" : ""}`}>
              <img className="img-fluid" src={currentPath === '/app/home' ? "/img/sidenav/home_active.svg" : "/img/sidenav/home.svg"} alt="" />
              <a onClick={() => { routeChange('home') }}>
              {intl.formatMessage({ id: 'home' })}</a>
            </li>
            {
              perRes.resolve(perRes.LIST_PARTNERS, currentUser) &&
              <li className={`${currentPath === '/app/partners' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/partners' ? "/img/sidenav/partners_active.svg" : "/img/sidenav/partners.svg"} alt="" />
                <a onClick={() => { routeChange('partners') }}>
                  Partners</a>
              </li>
            }
            {
              perRes.resolve(perRes.LIST_CUSTOMERS, currentUser) &&
              <li className={`${currentPath === '/app/customers' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/customers' ? "/img/sidenav/customer_active.svg" : "/img/sidenav/customer.svg"} alt="" />
                <a onClick={() => { routeChange('customers') }}>
                  Customers</a>
              </li>
            }
            {
              perRes.resolve(perRes.LIST_SUBSCRIPTIONS, currentUser) &&
              <li className={`${currentPath === '/app/subscriptions' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/subscriptions' ? "/img/sidenav/subscriptions_active.svg" : "/img/sidenav/subscriptions.svg"} alt="" />
                <a onClick={() => { routeChange('subscriptions') }}>
                {intl.formatMessage({ id: 'subscriptions.title' })}</a>
              </li>
            }
            {
              perRes.resolve(perRes.LIST_DOCUMENTS, currentUser) &&
              <li className={`${currentPath === '/app/documents' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/documents' ? "/img/sidenav/documents_active.svg" : "/img/sidenav/documents.svg"} alt="" />
                <a onClick={() => { routeChange('documents') }}>
                {intl.formatMessage({ id: 'documents.title' })}</a>
              </li>
            }
            {
              perRes.resolve(perRes.LIST_USERS, currentUser) &&
              <li className={`${currentPath === '/app/users' || currentPath === '/app/users/new' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/users' ? "/img/sidenav/users_active.svg" : "/img/sidenav/users.svg"} alt="" />
                <a onClick={() => { routeChange('users') }}>
                {intl.formatMessage({ id: 'users.title' })}</a>
              </li>
            }
            {/* {
              perRes.resolve(perRes.VISUALIZE_BILL, currentUser) &&
              <li className={`${currentPath === '/app/bill' ? "active" : ""}`}>
                <img className="img-fluid" src={currentPath === '/app/bill' ? "/img/sidenav/bill_active.svg" : "/img/sidenav/bill.svg"} alt="" />
                <a onClick={() => { routeChange('bill') }}>
                {intl.formatMessage({ id: 'bill.title' })}</a>
              </li>
            } */}
            {
              perRes.resolve(perRes.WILDCARD, currentUser) && <li className={`${currentPath === '/app/activity' ? "active last-nav-item" : "last-nav-item"}`}>
                <img className="img-fluid" src={currentPath === '/app/activity' ? "/img/sidenav/bill_active.svg" : "/img/sidenav/bill.svg"} alt="" />
                <a onClick={() => { routeChange('activity') }}>
                {intl.formatMessage({ id: 'activity' })}</a>
              </li>
            }
            {
              (perRes.resolve(perRes.ADD_TRUSTED_CA, currentUser) || perRes.resolve(perRes.CREATE_PERMISSION, props.currentUser)) && <li className={`${currentPath === '/app/advanced-settings' ? "active last-nav-item" : "last-nav-item"}`}>
                <img className="img-fluid" src={currentPath === '/app/advanced-settings' ? "/img/sidenav/settings.svg" : "/img/sidenav/settings.svg"} alt="" />
                <a onClick={() => { routeChange('advanced-settings') }}>
                {intl.formatMessage({ id: 'advanced-settings' })}</a>
              </li>
            }
          </ul>
        </div>
          : ''
      }

    </div>

  )
}

const mapStateToProps = ({ theme, api }) => {
  const { currentUser } = api;
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, locale } = theme;

  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, locale, currentUser }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle, onLocaleChange })(HeaderNav);