import {
  DashboardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import * as perRes from '../services/PermissionsResolver'

const dashBoardNavTree = [{
  key: 'home',
  permission: perRes.WILDCARD,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: 'home.svg',
  active_icon: 'home_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'partners',
  permission: perRes.LIST_PARTNERS,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/partners`,
  title: 'partners',
  icon: 'partners.svg',
  active_icon: 'partners_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'customers',
  permission: perRes.LIST_CUSTOMERS,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/customers`,
  title: 'customers',
  icon: 'customer.svg',
  active_icon: 'customer_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'subscriptions',
  permission: perRes.LIST_SUBSCRIPTIONS,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/subscriptions`,
  title: 'subscriptions',
  icon: 'subscriptions.svg',
  active_icon: 'subscriptions_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'documents',
  permission: perRes.LIST_DOCUMENTS,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/documents`,
  title: 'documents',
  icon: 'documents.svg',
  active_icon: 'documents_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'users',
  permission: perRes.LIST_USERS,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/users`,
  title: 'users',
  icon: 'users.svg',
  active_icon: 'users_active.svg',
  breadcrumb: false,
  submenu: []
},
// {
//   key: 'bill',
//   permission: perRes.VISUALIZE_BILL,
//   customIcon: true,
//   path: `${APP_PREFIX_PATH}/bill`,
//   title: 'bill',
//   icon: 'bill.svg',
//   active_icon: 'bill_active.svg',
//   breadcrumb: false,
//   submenu: []
// },
{
  key: 'activity',
  permission: perRes.WILDCARD,
  customIcon: true,
  path: `${APP_PREFIX_PATH}/activity`,
  title: 'activity',
  icon: 'activity.svg',
  active_icon: 'activity_active.svg',
  breadcrumb: false,
  submenu: []
},
{
  key: 'advanced-settings',
  permission: [perRes.ADD_TRUSTED_CA, perRes.CREATE_PERMISSION],
  customIcon: true,
  path: `${APP_PREFIX_PATH}/advanced-settings`,
  title: 'advanced-settings',
  icon: 'settings.svg',
  active_icon: 'settings_active.svg',
  breadcrumb: false,
  submenu: []
}
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
