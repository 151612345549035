/* eslint-disable eqeqeq */
/* eslint-disable default-case */
export function resolve(permission, currentUser) {
  if (currentUser) {
    switch (permission) {
      case WILDCARD:
        return true;
      case CREATE_PARTNER:
        return currentUser.permissions.CREATE_PARTNERS;
      case LIST_SUBSCRIPTIONS:
        return currentUser.permissions.SUBSCRIPTION_LIST_AND_DETAIL;
      case CREATE_OWNER_USER:
        return currentUser.permissions.CREATE_OWNERS;
      case CREATE_PARTNER_USER:
        return currentUser.permissions.CREATE_USERS_PARTNERS;
      case CREATE_CUSTOMER_USER:
        return currentUser.permissions.CREATE_CUSTOMERS;
      case EDIT_AVATAR_PERMISSIONS:
        return currentUser.permissions.EDIT_USER_PERMISSIONS;
      case EDIT_CUSTOMER_PERMISSIONS :
        return currentUser.permissions.EDIT_CUSTOMER_PERMISSIONS ;
      case DELETE_USER_ALL:
        return currentUser.permissions.DELETE_USER_AVATAR;
      case HARD_DELETE_USER:
        return currentUser.permissions.HARD_DELETE_USER;
      case DELETE_AVATAR_CUSTOMER:
        return currentUser.permissions.DELETE_AVATAR_CUSTOMER;
      case LIST_USERS:
        return currentUser.permissions.USERS_LIST_AND_DETAIL;
      case LIST_DOCUMENTS:
        return currentUser.permissions.DOCUMENTS_LIST_AND_DETAIL;
      case SEND_REMINDER:
        return currentUser.permissions.SEND_SIGNATURE_REMINDER;
      case DOWNLOAD_DOCUMENTS:
        return currentUser.permissions.DOWNLOAD_DOCUMENTS;
      case DELETE_DOCUMENTS:
        return currentUser.permissions.DELETE_DOCUMENTS;
      case CHANGE_DEVICE_DESCRIPTION:
        return currentUser.permissions.CHANGE_DEVICE_DESCRIPTION;
      case DELETE_DEVICE:
        return currentUser.permissions.DELETE_DEVICE;
      case CREATE_DELETE_CENTRALIZED_USERS:
        return currentUser.permissions.CREATE_DELETE_CENTRALIZED_USER;
      case CREATE_DELETE_STAMPER_CERT:
        return currentUser.permissions.CREATE_DELETE_STAMPER_CERT;
      case LIST_CUSTOMERS:
        return currentUser.permissions.CUSTOMERS_LIST_AND_DETAIL;
      case VISUALIZE_BILL:
        return currentUser.permissions.VISUALIZE_BILL;
      case LINK_CENTRALIZED_USERS:
        return currentUser.permissions.LINK_CENTRALIZED_USERS;
      case LINK_DEVICE:
        return currentUser.permissions.LINK_DEVICE;
      case CUSTOMIZE_SUBSCRIPTION:
        return currentUser.permissions.CUSTOMIZE_SUBSCRIPTION;
      case ADD_TRUSTED_CA:
        return currentUser.permissions.ADD_TRUSTED_CA;
      case CREATE_CENTRALIZED_USER:
        return currentUser.permissions.CREATE_CENTRALIZED_USER;
      case DELETE_CENTRALIZED_USER:
        return currentUser.permissions.DELETE_CENTRALIZED_USER;
      case EDIT_CENTRALIZED_USER:
        return currentUser.permissions.EDIT_CENTRALIZED_USER;
      case CREATE_STAMPER_CERT:
        return currentUser.permissions.CREATE_STAMPER_CERT;
      case DELETE_STAMPER_CERT:
        return currentUser.permissions.DELETE_STAMPER_CERT;
      case EDIT_STAMPER_CERT:
        return currentUser.permissions.EDIT_STAMPER_CERT;
      case LIST_PARTNERS:
        return currentUser.type === 'owner' ? true : false;
      case SUBS_VIEW_HYPER_PARTNER:
        return currentUser.type === 'owner' ? true : false;
      case SUBS_VIEW_HYPER_CUSTOMER:
        return currentUser.type === 'owner' ? true : currentUser.type === 'partner' ? true : false;
      case CUSTOMERS_PARTNER_COLUMN:
        return currentUser.type === 'owner' ? true : false;
      case CUSTOMERS_DETAIL_PARTNER:
        return currentUser.type === 'owner' ? true : false;
      case VIEW_NOTIFICATION_URLS:
        return currentUser.permissions.VIEW_NOTIFICATION_URLS;
      case UPDATE_NOTIFICATION_URLS:
        return currentUser.permissions.UPDATE_NOTIFICATION_URLS;
      case VIEW_AUTOFIRMA:
        return currentUser.permissions.VIEW_AUTOFIRMA;
      case UPDATE_AUTOFIRMA:
        return currentUser.permissions.UPDATE_AUTOFIRMA;
      case VIEW_LEGALID_IMAGE:
        return currentUser.permissions.VIEW_LEGALID_IMAGE;
      case UPDATE_LEGALID_IMAGE:
        return currentUser.permissions.UPDATE_LEGALID_IMAGE;
      case VIEW_ALLOW_ROTATION:
        return currentUser.permissions.VIEW_ALLOW_ROTATION;
      case UPDATE_ALLOW_ROTATION:
        return currentUser.permissions.UPDATE_ALLOW_ROTATION;
      case VIEW_DOCUMENT_DOWNLOAD:
        return currentUser.permissions.VIEW_DOCUMENT_DOWNLOAD;
      case UPDATE_DOCUMENT_DOWNLOAD:
        return currentUser.permissions.UPDATE_DOCUMENT_DOWNLOAD;
      case VIEW_DOCUMENT_PREVIEW:
        return currentUser.permissions.VIEW_DOCUMENT_PREVIEW;
      case UPDATE_DOCUMENT_PREVIEW:
        return currentUser.permissions.UPDATE_DOCUMENT_PREVIEW;
      case VIEW_NOTIFICATIONS:
        return currentUser.permissions.VIEW_NOTIFICATIONS;
      case UPDATE_NOTIFICATIONS:
        return currentUser.permissions.UPDATE_NOTIFICATIONS;
      case VIEW_TSA:
        return currentUser.permissions.VIEW_TSA;
      case UPDATE_TSA:
        return currentUser.permissions.UPDATE_TSA;
      case VIEW_VERIFY_EMAIL:
        return currentUser.permissions.VIEW_VERIFY_EMAIL;
      case UPDATE_VERIFY_EMAIL:
        return currentUser.permissions.UPDATE_VERIFY_EMAIL;
      case VIEW_MULTISIGNATURE:
        return currentUser.permissions.VIEW_MULTISIGNATURE;
      case UPDATE_MULTISIGNATURE:
        return currentUser.permissions.UPDATE_MULTISIGNATURE;
      case VIEW_GEOLOCATION:
        return currentUser.permissions.VIEW_GEOLOCATION;
      case UPDATE_GEOLOCATION:
        return currentUser.permissions.UPDATE_GEOLOCATION;
      case VIEW_WATERMARK:
        return currentUser.permissions.VIEW_WATERMARK;
      case UPDATE_WATERMARK:
        return currentUser.permissions.UPDATE_WATERMARK;
      case VIEW_SANITIZE:
        return currentUser.permissions.VIEW_SANITIZE;
      case UPDATE_SANITIZE:
        return currentUser.permissions.UPDATE_SANITIZE;
      case VIEW_POLICY:
        return currentUser.permissions.VIEW_POLICY;
      case UPDATE_POLICY:
        return currentUser.permissions.UPDATE_POLICY;
      case VIEW_EXPIRATION_DAYS:
        return currentUser.permissions.VIEW_EXPIRATION_DAYS;
      case UPDATE_EXPIRATION_DAYS:
        return currentUser.permissions.UPDATE_EXPIRATION_DAYS;
      case LINK_STAMPER_CERT:
        return currentUser.permissions.LINK_STAMPER_CERT;
      case VIEW_BIO_METADATA:
        return currentUser.permissions.VIEW_BIO_METADATA;
      case UPDATE_BIO_METADATA:
        return currentUser.permissions.UPDATE_BIO_METADATA;
      case VIEW_BIO_SECURE:
        return currentUser.permissions.VIEW_BIO_SECURE;
      case UPDATE_BIO_SECURE:
        return currentUser.permissions.UPDATE_BIO_SECURE;
      case CREATE_PERMISSION:
        return currentUser.permissions.CREATE_PERMISSION;
      case CUSTOMIZE_RGPD:
        return currentUser.permissions.CUSTOMIZE_RGPD;
      case CHANGE_SUBSCRIPTION_PASSWORD:
        return currentUser.permissions.CHANGE_SUBSCRIPTION_PASSWORD;
    }
  } else {
    return false
  }

}

export const CREATE_PARTNER = 1;
export const LIST_SUBSCRIPTIONS = 2;
export const CREATE_OWNER_USER = 3;
export const CREATE_PARTNER_USER = 4;
export const CREATE_CUSTOMER_USER = 5;
export const EDIT_AVATAR_PERMISSIONS = 6;
export const DELETE_USER_ALL = 7;
export const LIST_USERS = 8;
export const LIST_DOCUMENTS = 9;
export const SEND_REMINDER = 10;
export const DOWNLOAD_DOCUMENTS = 11;
export const DELETE_DOCUMENTS = 12;
export const CHANGE_DEVICE_DESCRIPTION = 13;
export const DELETE_DEVICE = 14;
export const CREATE_DELETE_CENTRALIZED_USERS = 15;
export const CREATE_DELETE_STAMPER_CERT = 16;
export const LIST_CUSTOMERS = 17;
export const VISUALIZE_BILL = 18;
export const EDIT_CUSTOMER_PERMISSIONS  = 19;
export const DELETE_AVATAR_CUSTOMER  = 20;
export const LINK_CENTRALIZED_USERS  = 21;
export const LINK_DEVICE  = 22;
export const HARD_DELETE_USER  = 23;
export const CUSTOMIZE_SUBSCRIPTION  = 24;
export const ADD_TRUSTED_CA  = 25;
export const CREATE_CENTRALIZED_USER  = 26;
export const DELETE_CENTRALIZED_USER  = 27;
export const EDIT_CENTRALIZED_USER  = 28;
export const CREATE_STAMPER_CERT  = 29;
export const DELETE_STAMPER_CERT  = 30;
export const EDIT_STAMPER_CERT  = 31;
export const LIST_PARTNERS = 998;
export const WILDCARD = 999;

export const SUBS_VIEW_HYPER_PARTNER = 500;
export const SUBS_VIEW_HYPER_CUSTOMER = 501;
export const CUSTOMERS_PARTNER_COLUMN = 502
export const CUSTOMERS_DETAIL_PARTNER = 503

export const VIEW_NOTIFICATION_URLS = 32;
export const UPDATE_NOTIFICATION_URLS = 33;
export const VIEW_AUTOFIRMA = 34;
export const UPDATE_AUTOFIRMA = 35;
export const VIEW_LEGALID_IMAGE = 36;
export const UPDATE_LEGALID_IMAGE = 37;
export const VIEW_ALLOW_ROTATION = 38;
export const UPDATE_ALLOW_ROTATION = 39;
export const VIEW_DOCUMENT_DOWNLOAD = 40;
export const UPDATE_DOCUMENT_DOWNLOAD = 41;
export const VIEW_DOCUMENT_PREVIEW = 42;
export const UPDATE_DOCUMENT_PREVIEW = 43;
export const VIEW_NOTIFICATIONS = 44;
export const UPDATE_NOTIFICATIONS = 45;
export const VIEW_TSA = 46;
export const UPDATE_TSA = 47;
export const VIEW_VERIFY_EMAIL = 48;
export const UPDATE_VERIFY_EMAIL = 49;
export const VIEW_MULTISIGNATURE = 50;
export const UPDATE_MULTISIGNATURE = 51;
export const VIEW_GEOLOCATION = 52;
export const UPDATE_GEOLOCATION = 53;
export const VIEW_WATERMARK = 54;
export const UPDATE_WATERMARK = 55;
export const VIEW_SANITIZE = 56;
export const UPDATE_SANITIZE = 57;
export const VIEW_POLICY = 58;
export const UPDATE_POLICY = 59;
export const VIEW_EXPIRATION_DAYS = 60;
export const UPDATE_EXPIRATION_DAYS = 61;
export const LINK_STAMPER_CERT = 63;
export const VIEW_BIO_METADATA = 64;
export const UPDATE_BIO_METADATA = 65;
export const VIEW_BIO_SECURE = 66;
export const UPDATE_BIO_SECURE = 67;
export const CREATE_PERMISSION = 68;
export const CUSTOMIZE_RGPD = 69;
export const CHANGE_SUBSCRIPTION_PASSWORD = 70;

